<template>
	<el-scrollbar class="uu-box">
		<div class="uu-goods">
			<!-- <div class="uu-goods-message">
				<div class="message-notice">
					<i class="el-icon-warning"></i>
					<div class="message-notice-main">
						<p>{{$t('seller.goodsList.tips1-1')}} <a href="#">{{$t('seller.goodsList.tips1-2')}}</a> 。</p>
						<p>{{$t('seller.goodsList.tips2-1')}}，<a href="#">{{$t('seller.goodsList.tips2-2')}}</a>。</p>
						<p>{{$t('seller.goodsList.tips3-1')}}<a href="#">{{$t('seller.goodsList.tips3-2')}}</a></p>
					</div>
				</div>
			</div> -->
			<el-tabs v-model="activeTabs" @tab-click="handleTabsClick">
				<el-tab-pane v-for="(item,index) in tabsOption" :key="index" :label="item.label" :name="item.value"></el-tab-pane>
			</el-tabs>
			<div class="uu-goods-main">
				<div class="uu-query">
					<el-form ref="queryForm" :model="queryForm" :inline="true">
						<el-form-item>
							<el-input v-model="queryForm.goods_name" clearable>
								<template slot="prefix">{{$t('seller.screen.goodsName')}}</template>
							</el-input>
						</el-form-item>
						<el-form-item>
							<el-input v-model="queryForm.goods_ids" clearable :placeholder="$t('seller.placeholder.ids')">
								<template slot="prefix">{{$t('seller.screen.goodsId')}}</template>
							</el-input>
						</el-form-item>
						<el-form-item>
							<el-input v-model="queryForm.bar_code" clearable>
								<template slot="prefix">{{$t('seller.screen.goodsCode')}}</template>
							</el-input>
						</el-form-item>
						<el-form-item>
							<div class="el-input el-input--prefix">
								<div class="el-input__inner">
									<div class="range-number">
										<input type="text" v-model="queryForm.price_from" maxlength="15"
											class="input-medium" @blur="rangeInputBlur" @focus="rangeInputFocus"
											autocomplete="off" :placeholder="$t('seller.placeholder.minimum')"
											oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)">
										<span class="range-separate">{{$t('seller.unit.to')}}</span>
										<input type="text" v-model="queryForm.price_to" maxlength="15"
											class="input-medium" @blur="rangeInputBlur" @focus="rangeInputFocus"
											autocomplete="off" :placeholder="$t('seller.placeholder.highest')"
											oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)">
									</div>
								</div>
								<span class="el-input__prefix">{{$t('seller.screen.price')}}</span>
							</div>
						</el-form-item>
						<el-form-item>
							<el-select v-model="queryForm.cate_id" clearable>
								<template slot="prefix">{{$t('seller.screen.primaryCate')}}</template>
								<el-option v-for="item in cateList" :key="item.cate_id" :label="item.cate_name"
									:value="item.cate_id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-select v-model="queryForm.store_cate_id" clearable>
								<template slot="prefix">{{$t('seller.screen.shopCate')}}</template>
								<template v-for="item in shopCateList">
									<el-option :key="item.cate_id" :label="item.cate_name" :value="item.cate_id"></el-option>
									<template v-if="item.children && item.children.length>0">
										<el-option style="padding-left: 40px;" v-for="child in item.children" :key="child.cate_id" :label="child.cate_name" :value="child.cate_id"></el-option>
									</template>
								</template>
							</el-select>
						</el-form-item>
						<!-- <el-form-item>
							<el-select v-model="queryForm.auctionType">
								<template slot="prefix">{{$t('seller.screen.babyType')}}</template>
								<el-option v-for="(item, index) in goodsTypeOption" :key="index" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item> -->
						<el-form-item>
							<div class="el-input el-input--prefix">
								<div class="el-input__inner">
									<div class="range-number">
										<input type="text" v-model="queryForm.sales_from" maxlength="15"
											class="input-medium" @blur="rangeInputBlur" @focus="rangeInputFocus"
											autocomplete="off" :placeholder="$t('seller.placeholder.minimum')"
											oninput="value=value.replace(/[^\d]/g,'')">
										<span class="range-separate">{{$t('seller.unit.to')}}</span>
										<input type="text" v-model="queryForm.sales_to" maxlength="15"
											class="input-medium" @blur="rangeInputBlur" @focus="rangeInputFocus"
											autocomplete="off" :placeholder="$t('seller.placeholder.highest')"
											oninput="value=value.replace(/[^\d]/g,'')">
									</div>
								</div>
								<span class="el-input__prefix">{{$t('seller.screen.sales')}}</span>
							</div>
						</el-form-item>
						<!-- <el-form-item>
							<el-select v-model="queryForm.auditStatus" clearable>
								<template slot="prefix">{{$t('seller.screen.auditStatus')}}</template>
								<el-option v-for="(item, index) in auditStatusOption" :key="index" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item> -->
						<el-form-item v-if="activeTabs=='in_stock'">
							<el-select v-model="queryForm.downShelfStatus" clearable>
								<template slot="prefix">{{$t('seller.screen.offShelf')}}</template>
								<el-option v-for="(item, index) in offShelfOption" :key="index" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button @click="handleSearchList" round>{{$t('seller.actions.search')}}</el-button>
							<span class="clear-btn" @click="handleSearchClear"><i class="el-icon-brush"></i>{{$t('seller.actions.reset')}}</span>
						</el-form-item>
					</el-form>
				</div>
				<div class="uu-action">
					<ul>
						<li>
							<router-link :to="{ name: 'sellPublish' }">
								<el-button type="primary" round>{{$t('seller.actions.publishGoods')}}</el-button>
							</router-link>
						</li>
						<li>
							<div class="select-sum">{{$t('seller.screen.selected')}} {{selectIds.length>0?selectIds.length:''}}</div>
						</li>
						<li>
							<el-button @click="handleListDelete" round>{{$t('seller.actions.batchDelete')}}</el-button>
						</li>
						<template v-if="activeTabs=='on_sale'">
							<li>
								<el-button @click="handleLowerGoods" round>{{$t('seller.actions.batchOffshelf')}}</el-button>
							</li>
						</template>
						<template v-if="activeTabs=='in_stock'">
							<li>
								<el-button @click="handleBatchUpperGoods()" round>{{$t('seller.actions.batchShelves')}}</el-button>
							</li>
						</template>
					</ul>
				</div>
				<el-table :data="goodsList" style="width: 100%" ref="goodsTable"
					@selection-change="handleTableListChange" @sort-change="handleSortListChange">
					<el-table-column type="selection" width="45">
					</el-table-column>
					<el-table-column :label="$t('seller.tableHead.goodsName')" min-width="300">
						<template slot-scope="scope">
							<div class="uu-goods-item">
								<img :src="scope.row.default_image" class="item-pic" @error="imageLoadError" alt="">
								<div class="item-main">
									<div class="item-span"><a href="#" class="item-title">{{scope.row.goods_name}}</a>
									</div>
									<div class="item-text">
										<span>ID:{{scope.row.goods_id}}</span>
										<span class="number"
											v-if="scope.row.goods_code">{{$t('seller.goodsList.code')}}:{{scope.row.goods_code}}</span>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="price" :label="$t('seller.tableHead.price')" min-width="80" sortable="custom" align="center">
						<template slot-scope="scope">¥{{scope.row.price}}</template>
					</el-table-column>
					<el-table-column prop="stock" :label="$t('seller.tableHead.stock')" sortable="custom" min-width="80">
					</el-table-column>
					<el-table-column prop="sales" :label="$t('seller.tableHead.sales')" min-width="80">
						<template slot-scope="scope">{{scope.row.sales?scope.row.sales:0}}</template>
					</el-table-column>
					<el-table-column prop="add_time" :label="$t('seller.tableHead.createTime')" sortable="custom" min-width="140">
						<template slot-scope="scope">
							<div>{{scope.row.add_time | parseTime('{y}-{m}-{d} {h}:{i}')}}</div>
							<div class="uu-table-tags">
								<el-tag :type="scope.row.if_show | statusFilter" size="mini">{{ scope.row.if_show === 1?$t('seller.tag.shelves'):$t('seller.tag.offshelf') }}</el-tag>
								<el-tag type="danger" v-if="scope.row.is_violation === 1" size="mini">{{$t('seller.tag.illegal')}}</el-tag>
							</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('seller.tableHead.handle')" min-width="80">
						<template slot-scope="scope">
							<ul class="uu-table-action">
								<li>
									<router-link class="action-item"
										:to="{ name: 'sellEdit', params: { id: scope.row.goods_id }}">{{$t('seller.actions.editGoods')}}</router-link>
								</li>
								<li v-if="scope.row.if_show==1"><span class="action-item"
										@click="handleLowerGoods(scope.row.goods_id)">{{$t('seller.actions.immediateOffshelf')}}</span></li>
								<li v-else><span class="action-item" @click="handleUpperGoods(scope.row)">{{$t('seller.actions.immediateShelves')}}</span>
								</li>
								<!-- <li><a href="">{{$t('seller.actions.more')}}</a></li> -->
							</ul>
						</template>
					</el-table-column>
					<template slot="empty">{{emptyText}}</template>
				</el-table>
				<div class="uu-footer">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page.sync="pagecurr" :page-size="pagesize" layout="total, prev, pager, next"
						:prev-text="$t('seller.pagination.prevText')" :next-text="$t('seller.pagination.nextText')" :total="listTotal">
					</el-pagination>
				</div>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
	import { categoryList, categoryShop } from '@/api/seller/category'
	import { fetchList, goodsUpdates, goodsDelete } from '@/api/seller/goods'
	import theme from "@/assets/css/mixin.scss"
	
	const defaultForm = {
		goods_name: '',
		goods_ids: '',
		bar_code: '',
		price_from: '',
		price_to: '',
		cate_id: '',
		store_cate_id: '',
		// auctionType: '',
		sales_from: '',
		sales_to: '',
		auditStatus: '',
		downShelfStatus: '',
	}
	
	export default {
		filters: {
			statusFilter(status) {
				const statusMap = ['warning', 'success']
				return statusMap[status]
			}
		},
		data() {
			return {
				activeTabs: 'all',
				tabsOption: this.$t('seller.goodsList.tabsOption'),
				queryForm: Object.assign({}, defaultForm),
				goodsTypeOption: this.$t('seller.goodsList.goodsTypeOption'),
				auditStatusOption: this.$t('seller.goodsList.auditStatusOption'),
				offShelfOption: this.$t('seller.goodsList.offShelfOption'),
				cateList: [],
				shopCateList: [],
				goodsList: [],
				pagesize: 10,
				pagecurr: 1,
				listTotal: 0,
				selectIds: [],
				orderField: '',
				orderType: '',
				emptyText: '',
			}
		},
		created() {
			this.activeTabs = this.$route.query.type ? this.$route.query.type : 'all';
			this.getGoodsList();
			this.getCateList();
			this.getShopCateList();
		},
		methods: {
			imageLoadError(e) {
				e.target.src = this.$imageErrorGoods;
				e.target.style.objectFit = 'fill';
			},
			handleTabsClick(tab, event) {
				this.$router.push({
					query: {
						type: tab.name
					}
				})
				this.handleSearchClear();
			},
			rangeInputFocus(event) {
				event.target.parentNode.parentNode.style.backgroundColor = '#ffffff';
				event.target.parentNode.parentNode.style.borderColor = theme.colorPrimary;
			},
			rangeInputBlur(event) {
				event.target.parentNode.parentNode.style.backgroundColor = '';
				event.target.parentNode.parentNode.style.borderColor = '';
			},
			getGoodsList() {
				let loading = this.$loading();
				let params = {
					...this.queryForm,
					page_index: (this.pagecurr - 1) * this.pagesize,
					page_size: this.pagesize
				}
				if (params.goods_ids) {
					params.goods_ids = params.goods_ids.replace(/ /gm, ',')
				}
				if (this.activeTabs == 'on_sale') {
					params.is_show = 1;
				} else if (this.activeTabs === 'in_stock') {
					params.is_show = 0;
				}
				if (this.orderField) {
					params.order_field = this.orderField
				}
				if (this.orderType) {
					params.order_type = this.orderType
				}
				fetchList(params).then(response => {
					loading.close()
					if (response.data.list.length > 0) {
						this.goodsList = response.data.list
						this.emptyText = '';
					} else {
						this.goodsList = [];
						this.emptyText = this.$t('seller.empty.nodata');
					}
					this.listTotal = parseInt(response.data.total)
				}).catch(error => {
					loading.close()
				})
			},
			getCateList() {
				let params = {
					is_platform: 1,
					parent_id: 0,
				}
				categoryList(params).then(response => {
					this.cateList = response.data.list;
				})
			},
			getShopCateList() {
				categoryShop({is_platform: 0}).then(response => {
					this.shopCateList = response.data.list
				})
			},
			// 搜索提交
			handleSearchList() {
				this.pagecurr = 1;
				if (this.queryForm.price_from && this.queryForm.price_to && this.queryForm.price_from * 1 > this.queryForm.price_to * 1) {
					this.queryForm.price_from = '';
					this.queryForm.price_to = '';
					this.$message.error(this.$t('seller.errorMsg.comparePrice'));
					return false;
				}
				if (this.queryForm.sales_from && this.queryForm.sales_to && this.queryForm.sales_from * 1 > this.queryForm.sales_to * 1) {
					this.queryForm.sales_from = '';
					this.queryForm.sales_to = '';
					this.$message.error(this.$t('seller.errorMsg.compareSales'));
					return false;
				}
				this.getGoodsList()
			},
			handleSearchClear() {
				this.queryForm = Object.assign({}, defaultForm)
				this.pagecurr = 1;
				this.getGoodsList()
			},
			handleTableListChange(val) {
				let ids = [];
				val.forEach(item => {
					ids.push(item.goods_id);
				})
				this.selectIds = ids;
			},
			handleSortListChange(column) {
				this.orderField = column.prop
				if (column.order == 'ascending') {
					this.orderType = 'asc'
				} else {
					this.orderType = 'desc'
				}
				this.handleSearchList()
			},
			handleSizeChange(val) {
				this.pagesize = val;
			},
			handleCurrentChange(val) {
				this.pagecurr = val;
				this.getGoodsList();
			},
			// 立即上架
			handleUpperGoods(goods, type) {
				if (!goods.stock > 0) {
					this.$message.error(this.$t('seller.errorMsg.goodsStock'));
					return false;
				}
				this.$confirm(this.$t('seller.confirm.shelvesText'), this.$t('seller.confirm.shelvesTitle'), {
					confirmButtonText: this.$t('seller.actions.confirm'),
					cancelButtonText: this.$t('seller.actions.cancel'),
					type: 'warning'
				}).then(() => {
					this.onUpperLower(goods.goods_id, 1);
				}).catch(() => {
					this.$message(this.$t('seller.cancelMsg.shelves'));
				})
			},
			// 批量上架
			handleBatchUpperGoods() {
				let that = this;
				let goods_ids = [];
				let not_goods_ids = [];
				let goods_list = that.$refs.goodsTable.selection;
				goods_list.forEach(item => {
					if (item.stock > 0) {
						goods_ids.push(item.goods_id);
					} else {
						not_goods_ids.push(item.goods_id);
					}
				})
				if (!goods_ids.length > 0 && !not_goods_ids.length > 0) {
					that.$message.error(that.$t('seller.errorMsg.seleteShelvesGoods'));
					return false;
				} else if (!goods_ids.length > 0 && not_goods_ids.length > 0) {
					that.$alert(
						'<p style="color:#f56c6c;"><i class="el-icon-error" style="font-size:18px;"></i> '+ this.$t('seller.alert.goodsShelvesText2') + '</p><p>' +
						not_goods_ids.join(',') + '</p>', this.$t('seller.alert.goodsShelvesTitle'), {
							dangerouslyUseHTMLString: true
						})
					return false;
				}
				that.$confirm(that.$t('seller.confirm.shelvesText'), that.$t('seller.confirm.batchShelvesTitle'), {
					confirmButtonText: that.$t('seller.actions.confirm'),
					cancelButtonText: that.$t('seller.actions.cancel'),
					type: 'warning'
				}).then(() => {
					that.onUpperLower(goods_ids.join(','), 1, not_goods_ids.join(','));
				}).catch(() => {
					that.$message(that.$t('seller.cancelMsg.shelves'));
				})
			},
			// 立即/批量下架
			handleLowerGoods(id) {
				let that = this;
				let ids = '';
				let msgTitle = that.$t('seller.confirm.offshelfTitle');
				if (id > 0) {
					ids = id;
				} else {
					msgTitle = that.$t('seller.confirm.batchOffshelfTitle');
					ids = that.selectIds.join(',');
				}
				that.$confirm(that.$t('seller.confirm.offshelfText'), msgTitle, {
					confirmButtonText: that.$t('seller.actions.confirm'),
					cancelButtonText: that.$t('seller.actions.cancel'),
					type: 'warning'
				}).then(() => {
					that.onUpperLower(ids, 0);
				}).catch(() => {
					that.$message(that.$t('seller.cancelMsg.offshelf'));
				})
			},
			onUpperLower(ids, type, no_goods) {
				let params = {
					goods_ids: ids,
					is_show: type,
					source: 'pc'
				}
				goodsUpdates(params).then(response => {
					this.getGoodsList();
					if (type == 1 && no_goods) {
						this.$alert(
							'<p style="color:#67c23a;"><i class="el-icon-success" style="font-size:18px;"></i> '+ this.$t('seller.alert.goodsShelvesText1') +
							'</p><p style="margin-bottom:10px;">' + ids + '</p><p style="color:#f56c6c;"><i class="el-icon-error" style="font-size:18px;"></i> '+
							this.$t('seller.alert.goodsShelvesText2') +'</p><p>' + no_goods + '</p>', this.$t('seller.alert.goodsShelvesTitle'), {
								dangerouslyUseHTMLString: true
							})
					} else {
						if(type == 1){
							this.$message.success(this.$t('seller.successMsg.goodsShelves'))
						} else {
							this.$message.success(this.$t('seller.successMsg.goodsOffshelf'))
						}
						
					}
				})
			},
			// 删除数据
			handleListDelete(id) {
				let ids = '';
				if (id > 0) {
					ids = id;
				} else {
					ids = this.selectIds.join(',');
				}
				if (!ids) {
					this.$message.error(this.$t('seller.errorMsg.goodsDelete'));
					return false;
				}
				this.$confirm(this.$t('seller.confirm.batchDeleteText'), this.$t('seller.confirm.batchDeleteTitle'), {
					confirmButtonText: this.$t('seller.actions.confirm'),
					cancelButtonText: this.$t('seller.actions.cancel'),
					type: 'warning'
				}).then(() => {
					goodsDelete({goods_ids: ids}).then(response => {
						this.getGoodsList();
						this.$message.success(this.$t('seller.successMsg.delete'))
					}).catch(err => {
						console.log(err)
					})
				}).catch(() => {
					this.$message(this.$t('seller.cancelMsg.delete'));
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.uu-goods {
		.uu-goods-message {
			padding: 14px 22px;

			.message-notice {
				padding: 12px;
				border-radius: 9px;
				line-height: 18px;
				background-color: $--seller-notice-background;

				.el-icon-warning {
					color: $--seller-primary;
					float: left;
					font-size: 18px;
				}

				.message-notice-main {
					width: 100%;
					color: #666666;
					font-size: 12px;
					padding-left: 24px;
					padding-right: 15px;
					box-sizing: border-box;

					a {
						color: $--seller-primary;
					}
				}
			}
		}

		::v-deep .el-tabs__header {
			margin-bottom: 0;

			.el-tabs__nav-wrap {
				padding: 0 24px;
			}

			.el-tabs__nav-wrap::after {
				height: 1px;
			}
		}

		.uu-goods-main {
			padding: 18px 24px;

			.uu-query {
				margin: 0 -9px;

				::v-deep .el-form-item {
					width: calc(25% - 18px);
					margin: 0px 9px 18px;

					.el-form-item__content {
						.el-select {
							width: 100%;
						}
					}
				}

				::v-deep .el-form--inline {
					.el-form-item__content {
						width: 100%;

						.el-input__inner {
							padding-left: 72px;
							background-color: $--seller-filter-input-background;
						}

						.el-input__inner:focus,
						.el-input__inner:hover {
							background-color: #FFFFFF;
							border-color: $--seller-primary;
						}
					}
				}

				::v-deep .el-input__prefix {
					left: 10px;
				}

				.range-number {
					width: 142px;
					height: 34px;
					line-height: 34px;
					padding: 2px 0;
					display: flex;
				}

				.clear-btn {
					cursor: pointer;
					color: #666666;
					margin-left: 12px;
				}

				.clear-btn:hover {
					color: $--seller-primary;
				}
			}

			.range-number {
				.input-medium {
					width: 60px;
					height: 34px;
					line-height: 34px;
					border-radius: 4px;
					display: inline-block;
					vertical-align: middle;
					background-color: $--seller-filter-input-background;
					padding: 0 9px;
					box-sizing: border-box;
				}

				.input-medium:focus,
				.input-medium:hover {
					background-color: #FFFFFF;
				}

				.range-separate {
					padding: 0 5px;
					display: inline-block;
					vertical-align: middle;
				}
			}

			.uu-action {
				margin-bottom: 20px;

				li {
					display: inline-block;
					vertical-align: middle;
					margin-right: 12px;
				}

				.select-sum {
					width: 90px;
					font-size: 14px;
				}

				::v-deep .el-select .el-input__inner {
					border-radius: 20px;
				}
			}

			::v-deep .has-gutter {
				th {
					background-color: $--seller-thead-background-1;
				}

				th.el-table_1_column_1 {
					border-top-left-radius: 10px;
				}

				th.el-table_1_column_7 {
					border-top-right-radius: 10px;
				}
			}

			.uu-footer {
				text-align: center;
				padding: 15px 0;
			}

			.uu-goods-item {
				.item-pic {
					width: 72px;
					height: 72px;
					float: left;
					border-radius: 12px;
				}

				.item-main {
					min-height: 72px;
					margin-left: 82px;
				}

				.item-title {
					color: #333333;
					font-size: 14px;
					line-height: 18px;
				}

				.item-text {
					color: #888888;
					font-size: 12px;
					line-height: 20px;

					span {
						display: inline-block;
						vertical-align: middle;
					}

					.number {
						max-width: 120px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						margin-left: 10px;
					}
				}
			}

			.uu-table-action {
				.action-item {
					color: $--seller-link-color;
					cursor: pointer;
					font-size: 12px;
					&:hover {
						color: $--seller-link-hover;
					}
				}
			}
			.uu-table-tags {
				.el-tag {
					margin-left: 10px;
					&:first-child {
						margin-left: 0;
					}
				}
			}
		}
	}
</style>
